import React from "react";

const NotFound = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-900 text-white p-4">
      <div className="text-center space-y-4 max-w-lg">
        <p className="text-2xl md:text-3xl font-bold">Galactic Error!</p>
        <p className="text-lg md:text-xl">
          Human, the page you're searching for does not exist in our space-time
          continuum. 🛸
        </p>
        <p className="text-sm md:text-base mt-2">
          Error Translation for Aliens: [Zorp! Blip blip. Nada. 🪐]
        </p>
        <a
          href="/"
          className="mt-6 inline-block px-4 py-2 md:px-8 md:py-3 bg-red-500 rounded-full text-white hover:bg-red-600 transition duration-300"
        >
          Teleport to home base
        </a>
      </div>
    </div>
  );
};

export default NotFound;
