import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";

const MapItinerary = ({ itineraryData, mapRef, city }) => {
  const [images, setImages] = useState({});

  useEffect(() => {
    const fetchImages = async () => {
      const fetchImage = async (placeName, city) => {
        // Check if the image is already stored in the localStorage
        const storedImage = localStorage.getItem(`${placeName}, ${city}`);
        if (storedImage) {
          return storedImage;
        }

        const url = `${
          process.env.REACT_APP_api_url
        }api/placephoto?placeName=${encodeURIComponent(
          placeName
        )}&city=${encodeURIComponent(city)}`;

        try {
          const response = await fetch(url);
          const data = await response.json();

          let image = "";
          if (data.photoUrl) {
            image = data.photoUrl;
            // Store the image URL in localStorage
            localStorage.setItem(`${placeName}, ${city}`, data.photoUrl);
          } else {
            console.log(`No image found for ${placeName}`);
          }

          return image;
        } catch (error) {
          console.log(`Error fetching image for ${placeName}:`, error);
        }
      };

      const images = {};
      for (const item of itineraryData) {
        images[item.morning.placeName] = await fetchImage(
          item.morning.placeName,
          city
        );

        images[item.afternoon.placeName] = await fetchImage(
          item.afternoon.placeName,
          city
        );
        images[item.evening.placeName] = await fetchImage(
          item.evening.placeName,
          city
        );
      }
      setImages(images);
    };

    fetchImages();
  }, [itineraryData]);

  const points = itineraryData.flatMap((item, index) => [
    {
      ...item.morning,
      day: index + 1,
      time: "Morning",
      image: images[item.morning.placeName],
    },
    {
      ...item.afternoon,
      day: index + 1,
      time: "Afternoon",
      image: images[item.afternoon.placeName],
    },
    {
      ...item.evening,
      day: index + 1,
      time: "Evening",
      image: images[item.evening.placeName],
    },
  ]);

  const iconOptions = {
    iconSize: [32, 32],
    iconAnchor: [16, 32],
  };
  const startMarkerIcon = L.icon({
    ...iconOptions,
    iconSize: [50, 50], // Larger size
    iconUrl: process.env.PUBLIC_URL + "/start-icon.png",
  });
  const endMarkerIcon = L.icon({
    ...iconOptions,
    iconSize: [40, 40], // Larger size
    iconUrl: process.env.PUBLIC_URL + "/end-icon.gif",
  });
  const genericMarkerIcon = L.icon({
    ...iconOptions,
    iconUrl: "https://img.icons8.com/color/48/region-code.png",
  });

  return (
    <MapContainer
      center={[points[0].latitude, points[0].longitude]}
      zoom={11}
      style={{ height: "100%", width: "100%" }}
      ref={mapRef}
    >
      <TileLayer url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png" />
      {points.map((point, index) => {
        let markerIcon = genericMarkerIcon;
        let zIndexOffset = 0;
        if (index === 0) {
          markerIcon = startMarkerIcon;
          zIndexOffset = 1000; // High value to render above others
        } else if (index === points.length - 1) {
          markerIcon = endMarkerIcon;
          zIndexOffset = 1000; // High value to render above others
        }

        return (
          <Marker
            key={index}
            position={[point.latitude, point.longitude]}
            icon={markerIcon}
            zIndexOffset={zIndexOffset}
          >
            <Popup>
              <div
                style={{
                  backgroundColor: "black",
                  color: "white",
                  height: "50vh",
                  width: "100%",
                  overflow: "auto",
                }}
                className="popup-content"
              >
                <h3>{point.title}</h3>
                <h4>
                  Day {point.day} - {point.time}
                </h4>
                <p>{point.activity}</p>
                {point.image && (
                  <img
                    src={point.image}
                    alt={point.title}
                    style={{ width: "100%", height: "auto" }}
                  />
                )}
              </div>
            </Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
};

export default MapItinerary;
