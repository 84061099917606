import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const Suggestions = () => {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [images, setImages] = useState({});
  const [jsonData, setJsonData] = useState(null);
  const [formData, setFormData] = useState(null);

  const location = useLocation();
  const navigate = useNavigate(); // Move useNavigate hook to top level

  useEffect(() => {
    setJsonData(location.state?.jsonData);
    setFormData(location.state?.formData);
  }, [location.state?.jsonData, location.state?.formData]);

  // useEffect(() => {
  //   if (jsonData) {
  //     console.log("jsonData:", jsonData);
  //   }
  //   if (formData) {
  //     console.log("formData:", formData);
  //   }
  // }, [jsonData, formData]);

  // fetch images from backend
  useEffect(() => {
    if (jsonData) {
      const fetchImages = async () => {
        let imageMap = {};
        for (let place of jsonData.recommended_places) {
          try {
            const { placeName, city } = getPlaceNameAndCity(place.name);

            const response = await fetch(
              `${
                process.env.REACT_APP_api_url
              }api/placephoto?placeName=${encodeURIComponent(
                placeName
              )}&city=${encodeURIComponent(city)}`
            );

            const data = await response.json();

            if (data.photoUrl) {
              imageMap[place.name] = data.photoUrl;
              localStorage.setItem(`${place.name}`, data.photoUrl);
            } else {
              console.log(`No image found for ${place.name}`);
              imageMap[place.name] =
                "https://res.cloudinary.com/djtigzyfc/image/upload/f_auto,q_auto/v1/aitravel/z7as3zovlazz2g1olnoq"; // This is the default image.
            }
          } catch (error) {
            console.log(`Error fetching image for ${place.name}:`, error);
            imageMap[place.name] =
              "https://res.cloudinary.com/djtigzyfc/image/upload/f_auto,q_auto/v1/aitravel/z7as3zovlazz2g1olnoq"; // This is the default image.
          }
        }
        setImages(imageMap);
      };

      fetchImages();
    }
  }, [jsonData]);

  const getPlaceNameAndCity = (fullName) => {
    const parts = fullName.split(",");
    if (parts.length >= 2) {
      return {
        placeName: parts[0].trim(),
        city: parts[1].trim(),
      };
    }
    // Fallback to original string in unexpected format
    return {
      placeName: fullName,
      city: "",
    };
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".selected-card")) {
      setSelectedPlace(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClick = (place) => {
    setSelectedPlace(selectedPlace === place ? null : place);
  };

  const handleSelect = (placeName) => {
    const uniqueId = uuidv4();

    navigate(`/itinerary/${placeName}/${uniqueId}`);
  };

  const determineCardColor = (weather) => {
    if (weather.includes("sunny") || weather.includes("warm"))
      return "bg-yellow-500 text-black";
    if (weather.includes("cloudy")) return "bg-gray-400";
    if (weather.includes("misty")) return "bg-blue-400";
    return "bg-gray-800";
  };

  // Conditional rendering for jsonData
  if (!jsonData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-gradient-to-br from-gray-900 to-gray-800 min-h-screen text-white p-8 flex flex-col items-center justify-center">
      <h1 className="text-4xl mb-6">Recommended Places</h1>

      {selectedPlace && (
        <div className="fixed top-0 left-0 w-full h-full z-10 bg-black bg-opacity-30 backdrop-blur-md flex items-center justify-center">
          <div
            className={`selected-card relative w-4/5 h-4/5 transform transition-transform duration-300 shadow-2xl rounded-lg overflow-auto border border-gray-700 m-2 ${determineCardColor(
              selectedPlace.current_weather
            )}`}
            style={{ maxHeight: "80vh" }} // This line sets a maximum height to the selected card
          >
            {/* Close Button */}
            <button
              className="absolute top-4 right-4 bg-red-500 rounded-full p-2 w-8 h-8 flex items-center justify-center text-white font-bold"
              onClick={() => setSelectedPlace(null)}
            >
              &times;
            </button>

            {/* Rest of the card content */}
            <img
              src={
                images[selectedPlace.name] ||
                "https://res.cloudinary.com/djtigzyfc/image/upload/f_auto,q_auto/v1/aitravel/z7as3zovlazz2g1olnoq"
              }
              alt={selectedPlace.name}
              className="w-full object-cover"
              style={{ maxHeight: "35vh" }} // This line sets a maximum height to the image
            />

            <div className="p-4">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl">{selectedPlace.name}</h2>
                <button
                  className="px-6 py-2 bg-teal-600  border-teal-700 rounded-full transition duration-300 text-white font-bold hover:bg-teal-500 shadow-xl transform hover:scale-105"
                  onClick={() => handleSelect(selectedPlace.name)}
                >
                  Select
                </button>
              </div>
              <p className="mb-2">{selectedPlace.distance}</p>
              <p className="mb-4">{selectedPlace.current_weather}</p>
              <p>{selectedPlace.description}</p>

              <p className="mt-4">
                <strong>Best time to visit:</strong>{" "}
                {selectedPlace.best_time_to_visit}
              </p>
              <p className="mt-2">
                <strong>Visitors:</strong> {selectedPlace.crowd}
              </p>
              <p className="mt-2">
                <strong>Mode of travel:</strong> {selectedPlace.mode_of_travel}
              </p>
              <p className="mt-2">
                <strong>Ideal For:</strong> {selectedPlace.suited_for}
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {jsonData.recommended_places.map((place, index) => (
          <div
            key={index}
            className={`card relative w-full transition-transform duration-300 cursor-pointer shadow-lg rounded-lg overflow-hidden border border-gray-700 hover:scale-105 hover:shadow-xl active:scale-95 active:shadow-md ${
              selectedPlace === place ? "hidden" : ""
            } ${determineCardColor(place.current_weather)}`}
            onClick={() => handleClick(place)}
          >
            <div className="h-56 overflow-hidden">
              <img
                src={
                  images[place.name] ||
                  "https://res.cloudinary.com/djtigzyfc/image/upload/f_auto,q_auto/v1/aitravel/z7as3zovlazz2g1olnoq"
                }
                alt={place.name}
                className="w-full h-full object-cover"
              />
            </div>

            <div className="p-4 flex flex-col justify-between flex-grow">
              <h2 className="text-xl mb-2">{place.name}</h2>
              <p className="mb-2">{place.distance}</p>
              <p className="mb-2">{place.current_weather}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Suggestions;
