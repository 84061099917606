import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCvdivCXtCG-IVKY3JmpV29lsFkaf8bYcs",
  authDomain: "aitravel-1ec6a.firebaseapp.com",
  projectId: "aitravel-1ec6a",
  storageBucket: "aitravel-1ec6a.appspot.com",
  messagingSenderId: "672761756780",
  appId: "1:672761756780:web:3b84bf18c7928c3c74ac49",
  measurementId: "G-GJ3N0Q6VLX",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// instantiate auth for basic login and signup form
export const auth = getAuth(app);

// instantiate auth for signIn with Google
export const googleProvider = new GoogleAuthProvider();

// Adding Firestore db on frontend
export const db = getFirestore(app);
