import React, { useEffect } from "react";

export default function FlightBook() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://tp.media/content?trs=254919&shmarker=454456&locale=en_us&Checkbox_9=false&powered_by=true&primary=%230C131D&dark=%230C131D&light=%23FFFFFF&secondary=%23F1EDFC&promo_id=7293&campaign_id=200";
    script.charset = "utf-8";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return <div>{/* ... your component content ... */}</div>;
}
