import React from "react";

const AboutUs = () => {
  return (
    <div className="min-h-screen py-6 flex flex-col justify-center sm:py-12 bg-gray-800 text-white">
      <div className="relative py-3 sm:max-w-xl sm:mx-auto">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-400 to-purple-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
        <div className="relative px-4 py-10 bg-gray-900 shadow-lg sm:rounded-3xl sm:py-0 sm:px-20">
          <div className="max-w-md mx-auto">
            <div>
              <img src="new.png" className="h-25 sm:h-40" />
            </div>
            <div className="divide-y divide-gray-200">
              <div className="py-8 text-base leading-6 space-y-4 text-gray-300 sm:text-lg sm:leading-7">
                <p>
                  Welcome to aiTravel, your AI-powered journey planner. Our goal
                  is to revolutionize the way you travel by making itinerary
                  planning to even the most remote corners of the world
                  effortless and exciting.
                </p>
                <p>
                  With the power of AI, aiTravel curates personalized
                  itineraries that go beyond what traditional planning tools
                  offer. Unlike other services, aiTravel digs deep into the
                  possibilities, ensuring you get to experience the world in its
                  full essence, not just the common tourist spots.
                </p>
                <p>
                  Enjoy your next adventure with peace of mind and make the most
                  of every moment with aiTravel.
                </p>
              </div>
              <div className="pt-6 pb-2 text-base leading-6 font-bold sm:text-lg sm:leading-7">
                <p>
                  Want to get in touch? Reach out to us on{" "}
                  <a
                    href="mailto:contact@aitravel.live"
                    className="text-blue-400 hover:text-blue-500"
                  >
                    contact@aitravel.live
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
