import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Flights from "./pages/Flights";
import Visa from "./pages/Visa";

import FltDetails from "./pages/FltDetails";

import Map from "./components/Map";

import Photo from "./pages/photo";
import RoamReady from "./pages/RoamReady";
import Upload from "./pages/Upload";
import Itinerary from "./pages/Itinerary";
import Test from "./pages/Test";
import TestTwo from "./pages/TestTwo";
import Account from "./pages/Account";
import { AuthContextProvider } from "./context/AuthContext";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import AboutUs from "./pages/AboutUs";
import NotFound from "./pages/NotFound";
import FlightBook from "./pages/FlightBook";
import SuggestionsForm from "./pages/SuggestionsForm";
import Suggestions from "./pages/Suggestions";

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <Router>
          <Routes>
            {/* <Route path="/" element={<Home />} />
          <Route path="/flights" element={<Flights />} />
          <Route path="/visa" element={<Visa />} />
          <Route path="/upload" element={<Upload />} />
         
          <Route path="/fltdetails" element={<FltDetails />} />
          <Route path="/aircrafts" element={<Aircrafts />} />
          <Route path="/map" element={<Map />} />
          <Route path="/photo" element={<Photo />} />
          <Route path="/roamready" element={<RoamReady />} />
          <Route path="/itinerary/:id/:ignore" element={<Itinerary />} />
          <Route path="/test/" element={<Test />} />
          <Route path="/test2" element={<TestTwo />} /> */}
            <Route path="/" element={<RoamReady />} />
            <Route path="/itinerary/:id/:ignore" element={<Itinerary />} />
            {/* <Route path="/test/" element={<Test />} />
            <Route path="/test2" element={<TestTwo />} /> */}
            <Route path="/spotpick" element={<SuggestionsForm />} />
            <Route path="/suggestions/:id/:ignore" element={<Suggestions />} />
            <Route path="/flightbook" element={<FlightBook />} />
            <Route
              path="/account"
              element={
                <ProtectedRoute>
                  <Account />
                </ProtectedRoute>
              }
            />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/Signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Router>
      </AuthContextProvider>
    </div>
  );
}

export default App;
