import React, { useState, useEffect, useRef } from "react";
import CardItinerary from "../components/CardItinerary";
import StreamingItinerary from "../components/StreamingItinerary";
import MapItinerary from "../components/MapItinerary";
import { useLocation } from "react-router-dom";
import PreLoader2 from "../components/PreLoader2";
import "../styles/itinerary.css";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import BuyMeCoffee from "../components/BuyMeCoffee";
import FeedbackForm from "../components/FeebackForm";
import cat from "../components/catDataArray";
import {
  FaEllipsisV,
  FaTimes,
  FaSignInAlt,
  FaUserPlus,
  FaLightbulb,
  FaUser,
  FaSignOutAlt,
  FaInstagram,
  FaMapMarkedAlt,
  FaShareAlt,
  FaSave,
  FaShareSquare,
  FaEllipsisH,
  FaPaperPlane,
  FaHome,
} from "react-icons/fa";

// firestore-------
import { db } from "../config/firebase";
import { UserAuth } from "../context/AuthContext";
import {
  getDocs,
  collection,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";
import SuccessSnackbar from "../components/SuccessSnackbar";
// ----------

const Itinerary = () => {
  const [selectedItem, setSelectedItem] = useState(0);
  const [morningImage, setMorningImage] = useState("");
  const [afternoonImage, setAfternoonImage] = useState("");
  const [eveningImage, setEveningImage] = useState("");
  const [initialItineraryData, setInitialItineraryData] = useState(null);
  const [isValidPlace, setIsValidPlace] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [errorDialogMessage, setErrorDialogMessage] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);

  const { user, logout } = UserAuth();

  const [itineraryInput, setItineraryInput] = useState("");
  const mapRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  const itinearyRef = collection(db, "plans");

  // Function to toggle Share popup
  const toggleSharePopup = () => setIsSharePopupOpen(!isSharePopupOpen);

  // Feedback form
  const handleFeedbackSent = () => {
    alert("Feedback received. Thank you!");
  };

  // Three dot menu
  function toggleMenu() {
    setIsMenuOpen((prevState) => !prevState);
  }
  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("Firebase: Signed Out");
    } catch (err) {
      console.error(err);
    }
  };

  // Check if Mobile or Desktop/Laptop View
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);

  // Validate if city name is valid
  function validatePlaceName(placeName) {
    // This function now always return true, regardless of the place name.
    return true;
  }

  async function reSubmitTheForm(event) {
    event.preventDefault(); // Prevents the default form submission behavior

    setIsLoading(true); // Start loading

    const uniqueId = uuidv4();

    const url = `/itinerary/${itineraryInput}/${uniqueId}`;
    window.location.href = url; // Reloads the page with the specified URL
    setIsLoading(false); // Stop loading
  }

  useEffect(() => {
    const handleWindowSizeChange = () => {
      setIsMobile(window.innerWidth <= 1000);
    };

    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const placeName = id.charAt(0).toUpperCase() + id.slice(1);

  const LoadingAnimation = () => {
    const randomIndex = Math.floor(Math.random() * cat.length);
    const randomCatText = cat[randomIndex];

    return (
      <div className="fixed top-0 left-0 h-screen w-screen bg-gray-100 flex items-center justify-center">
        <div className="text-black text-center p-8  rounded-lg">
          <h2 className="text-2xl font-bold mb-4 text-blue-600">
            {randomCatText}
          </h2>
          <div className="relative">
            <PreLoader2 />
          </div>
          <p className="text-lg text-gray-800">
            {`Hold on. Preparing your ultimate Itinerary for ${placeName}.`}
          </p>
        </div>
      </div>
    );
  };

  useEffect(() => {
    async function fetchData() {
      if (placeName) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_api_url}api/travel/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ placeName }),
            }
          );

          const data = await response.json();

          if (!response.ok) {
            throw new Error(
              data.error || `Request failed with status ${response.status}`
            );
          }
          setInitialItineraryData(data.result);
        } catch (error) {
          console.error(error);
          setErrorDialogMessage(
            "Oops! Our server is currently under heavy load. Please try again later."
          );
          setErrorDialogOpen(true);
        }
      }
    }

    fetchData();
  }, [placeName]);

  const closeErrorDialog = () => {
    setErrorDialogOpen(false);
  };

  useEffect(() => {
    if (initialItineraryData && initialItineraryData !== "no cache") {
      setInitialMapCenter();
    }
  }, [initialItineraryData]);

  const setInitialMapCenter = () => {
    const selectedData = initialItineraryData[selectedItem];
    const mapCenter = [
      selectedData.morning.latitude,
      selectedData.morning.longitude,
    ];

    mapRef.current?.setView(mapCenter, 12); // Adjust zoom level to 12
  };

  const handleItemClick = (dayNum) => {
    setSelectedItem(dayNum);
  };

  const handleItemImagesChange = (
    index,
    morningImage,
    afternoonImage,
    eveningImage
  ) => {
    if (index === selectedItem) {
      setMorningImage(morningImage);
      setAfternoonImage(afternoonImage);
      setEveningImage(eveningImage);
    }
  };

  if (initialItineraryData === "no cache") {
    console.log(initialItineraryData);
    return <StreamingItinerary city={placeName} />;
  }
  if (!initialItineraryData) {
    return;
  }

  const currentUrl = window.location.href;

  const share = () => {
    if (!navigator.share) {
      alert("Sorry, your browser does not support the Share API");
      return;
    }

    navigator
      .share({
        title: document.title,
        text: "Check out this page!",
        url: currentUrl,
      })
      .catch((error) => console.error("Error sharing:", error));
  };

  // Add / Create function of Firebase db
  async function onSubmitUrl() {
    try {
      // Check if the currentUrl already exists for the authenticated user
      const querySnapshot = await getDocs(
        query(
          itinearyRef,
          where("userId", "==", user?.uid),
          where("url", "==", currentUrl)
        )
      );

      if (!querySnapshot.empty) {
        // Url already exists, do not add it again
        console.log("woah woah, you alrady saved this");
        return;
      }

      // Url does not exist, add it to the database
      await addDoc(itinearyRef, {
        placeName: placeName,
        url: currentUrl,
        date: serverTimestamp(),
        userId: user?.uid,
        userEmail: user?.email,
      });
    } catch (err) {
      console.log(err);
    }
  }

  // save itinerary to firebase database

  const save = () => {
    if (!isSaved) {
      setIsSaved(true);
      user ? onSubmitUrl() : navigate("/login");
      handleOpenSnackbar();
      console.log(currentUrl, placeName);
    }
  };

  // Instagram share button
  const shareWithInstagram = () => {
    const title = "Check out this awesome travel itinerary I made using AI";
    const encodedUrl = encodeURIComponent(currentUrl);
    const instagramUrl = `https://www.instagram.com/create/details/?title=${encodeURIComponent(
      title
    )}&url=${encodedUrl}`;

    window.open(instagramUrl, "_blank");
  };

  // Success message on clicking save button

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const mobileComponent = (
    <div className="min-h-screen bg-[#242424] text-white flex flex-col items-center justify-center">
      <Link to="/">
        <img src="/new.png" alt="Logo" className="mx-auto mt-0" />
      </Link>

      {/* Three-dot menu icon */}
      <div className="fixed top-4 right-4 z-40">
        <button className="text-white text-4xl" onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaEllipsisV />}
        </button>
      </div>

      {/* Menu */}
      {isMenuOpen && (
        <div className="fixed top-0 right-0 bottom-0 z-30 bg-black bg-opacity-95 transition duration-300 transform translate-x-0 w-full sm:max-w-xs ">
          <div className="h-full flex flex-col justify-center mt-[-15rem] items-start pl-10">
            {/* Check if user is logged in */}
            {user && user.email ? (
              <>
                {/* Display Account button */}
                <button
                  onClick={() => navigate("/account")}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaUser className="mr-2" /> Account
                </button>

                {/* Display Logout button */}
                <button
                  onClick={handleLogout}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaSignOutAlt className="mr-2" /> Logout
                </button>
              </>
            ) : (
              <>
                {/* Display Login button */}
                <button
                  onClick={() => navigate("/login")}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaSignInAlt className="mr-2" /> Login
                </button>

                {/* Display Signup button */}
                <button
                  onClick={() => navigate("/signup")}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaUserPlus className="mr-2" /> Signup
                </button>
              </>
            )}

            {/* Suggestion button */}
            <button
              onClick={() => navigate("/about-us")}
              className="text-white text-xl flex items-center py-2 px-4 w-full text-left"
            >
              <FaLightbulb className="mr-2" /> About Us
            </button>
            <div className="fixed bottom-6 left-4 bg-gray-600 text-white rounded-full p-3 shadow-lg transition duration-300 ease-in-out hover:bg-green-600 focus:outline-none">
              <FeedbackForm onFeedbackSent={handleFeedbackSent} />
            </div>
          </div>
        </div>
      )}

      <div className="container mx-auto pb-4 px-4 sm:px-8">
        <form className="pb-4" onSubmit={reSubmitTheForm}>
          <div className="relative flex items-center w-full">
            <input
              className={`p-3 rounded-2xl border-2 text-black font-bold pl-12 w-full ${
                validationError ? "bg-red-200" : ""
              }`}
              type="text"
              placeholder={
                validationError
                  ? "oops! Invalid destination"
                  : "New Destination?"
              }
              value={itineraryInput}
              onChange={(e) => setItineraryInput(e.target.value)}
            />
            <input
              type="submit"
              value="Go"
              className="absolute right-0 mr-2 px-3 py-2 bg-blue-500 text-white rounded-3xl cursor-pointer hover:bg-blue-700 transition-all duration-300 ease-in-out"
            />
          </div>
        </form>
        {/* Floating action button for Map */}
        <button
          onClick={() => setShowMap(true)}
          className="fixed bottom-6 right-4 w-12 h-12 rounded-full bg-green-600 opacity-70 text-white flex items-center justify-center text-2xl shadow-lg z-50"
        >
          <FaMapMarkedAlt />
        </button>
        {showMap && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "black",
              zIndex: 1000,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <button
              onClick={() => setShowMap(false)}
              className="px-2 py-[2px] font-semibold font-mono text-red-200"
              style={{
                alignSelf: "flex-end",
              }}
            >
              Close
            </button>
            <MapItinerary
              itineraryData={initialItineraryData}
              mapRef={mapRef}
              city={placeName}
            />
          </div>
        )}

        <div className="text-center flex justify-between items-center">
          {" "}
          {/* <-- Added items-center to align the title and button vertically */}
          <div>
            <h2 className="text-2xl font-bold text-left mb-0 py-1">
              Four Day Trip to {placeName}
            </h2>
            <h3 className="text-base text-[#92c0d7] text-left mt-0">
              Great! Here's a detailed Itinerary for your trip
            </h3>
          </div>
          {/* Share button */}
          <div>
            <button
              className="flex items-center justify-center bg-blue-500 text-white rounded-3xl cursor-pointer h-10 w-10 p-2 transition-all duration-300 ease-in-out hover:bg-blue-700"
              onClick={toggleSharePopup}
            >
              <FaPaperPlane />
            </button>
          </div>
          {/* Share Popup */}
          {isSharePopupOpen && (
            <div
              className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center z-50"
              onClick={toggleSharePopup} // close popup when clicking outside
            >
              <div
                className="relative bg-black w-3/4 h-1/2 md:w-1/2 md:h-1/3 p-6 rounded-lg flex flex-col justify-center items-center"
                onClick={(e) => e.stopPropagation()} // prevent popup close when clicking inside
              >
                {/* Instagram Button */}
                <button
                  className="w-full h-10 p-2 mb-2 bg-[#e1306c] text-white rounded-2xl flex items-center justify-center hover:bg-[#bd2f62] transition-all duration-300 ease-in-out"
                  onClick={shareWithInstagram}
                >
                  <FaInstagram className="mr-2" />
                  Post on Instagram
                </button>
                {/* Share Button */}
                <button
                  className="w-full h-10 p-2 mb-2 bg-[#0a66c2] text-white rounded-2xl flex items-center justify-center hover:bg-[#084d8a] transition-all duration-300 ease-in-out "
                  onClick={share}
                >
                  <FaShareSquare className="mr-2" />
                  Share Link
                </button>
                {/* Save Button */}
                <button
                  className="w-full h-10 p-2 text-white rounded-2xl flex items-center justify-center bg-[#34a853] hover:bg-[#2c8c43] transition-all duration-300 ease-in-out"
                  onClick={save}
                  disabled={isSaved}
                >
                  <FaSave className="mr-2" />
                  Save Itinerary
                </button>
              </div>
            </div>
          )}
        </div>

        <div className="bg-[#242424] text-white mt-8">
          <div className="">
            {initialItineraryData.slice(0, 4).map((item, index) => (
              <CardItinerary
                key={index}
                item={item}
                dayNum={index}
                isSelected={index === selectedItem}
                onItemImagesChange={handleItemImagesChange}
                city={placeName}
              />
            ))}
          </div>
        </div>

        <SuccessSnackbar
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
        />
      </div>
    </div>
  );

  const desktopComponent = (
    <>
      {/* Three-dot menu icon */}
      <div className="fixed top-4 right-4 z-40">
        <button className="text-blue-400 text-4xl" onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaEllipsisV />}
        </button>
      </div>

      {/* Menu */}
      {isMenuOpen && (
        <div className="fixed top-0 right-0 bottom-0 z-30 bg-black bg-opacity-95 transition duration-300 transform translate-x-0 w-full sm:max-w-xs ">
          <div className="h-full flex flex-col justify-center mt-[-15rem] items-start pl-10">
            {/* Check if user is logged in */}
            {user && user.email ? (
              <>
                {/* Display Account button */}
                <button
                  onClick={() => navigate("/account")}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaUser className="mr-2" /> Account
                </button>

                {/* Display Logout button */}
                <button
                  onClick={handleLogout}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaSignOutAlt className="mr-2" /> Logout
                </button>
              </>
            ) : (
              <>
                {/* Display Login button */}
                <button
                  onClick={() => navigate("/login")}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaSignInAlt className="mr-2" /> Login
                </button>

                {/* Display Signup button */}
                <button
                  onClick={() => navigate("/signup")}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaUserPlus className="mr-2" /> Signup
                </button>
              </>
            )}

            {/* Suggestion button */}
            <button
              onClick={() => navigate("/about-us")}
              className="text-white text-xl flex items-center py-2 px-4 w-full text-left"
            >
              <FaLightbulb className="mr-2" /> About Us
            </button>
            <div className="fixed bottom-6 left-4 bg-gray-600 text-white rounded-full p-3 shadow-lg transition duration-300 ease-in-out hover:bg-blue-600 focus:outline-none">
              <FeedbackForm onFeedbackSent={handleFeedbackSent} />
            </div>
          </div>
        </div>
      )}
      <div className="text-white bg-[#152535] fixed top-0 left-0 h-[100px] w-1/2 z-10 pt-4 px-4">
        <h2 className="mb-0">
          {`${
            placeName.length > 40
              ? `${placeName.substring(0, 40)}...`
              : placeName
          }`}
        </h2>
        <h3 className="text-[#92c0d7] mt-3">
          Great! Here's a detailed Itinerary for your four day trip
        </h3>
        <form
          className="absolute top-0 right-12 mt-4 w-64 transition-all duration-300 ease-in-out transform hover:scale-105 flex items-center"
          onSubmit={reSubmitTheForm}
        >
          <div className="relative flex items-center">
            <input
              className={`p-3 rounded-2xl border-2 text-black font-bold pl-4 w-full ${
                validationError ? "bg-red-200" : ""
              }`}
              type="text"
              placeholder={
                validationError ? "Invalid destination!" : "New Destination?"
              }
              value={itineraryInput}
              onChange={(e) => setItineraryInput(e.target.value)}
            />

            <input
              type="submit"
              value="Go"
              className="absolute right-2 px-3 py-2 bg-blue-500 text-white rounded-3xl cursor-pointer hover:bg-blue-700 transition-all duration-300 ease-in-out "
            />
          </div>
        </form>
        <div className="flex justify-end mt-[-33px] mr-8">
          <div className="relative group inline-block mt-6 ml-auto">
            <button
              className="flex items-center justify-center bg-gray-200 text-gray-800 hover:bg-blue-500 hover:text-white  rounded-lg shadow-md h-10 px-4 transition-transform duration-300 transform group-hover:animate-shake"
              onClick={toggleSharePopup}
            >
              <span>Save & Share</span>
            </button>
          </div>

          {/* Popup */}
          {isSharePopupOpen && (
            <div className="fixed inset-0 flex items-center justify-center z-50">
              <div
                className="fixed inset-0 bg-gray-900 opacity-70"
                onClick={toggleSharePopup}
              ></div>
              <div className="relative bg-gray-200 rounded-lg shadow-lg w-64 max-w-xs m-4">
                <div className="p-4 flex flex-col justify-center items-center space-y-2">
                  <button
                    className="w-full py-2 px-4 flex justify-center items-center space-x-2 rounded-lg bg-indigo-500 text-white hover:bg-indigo-700 transition duration-300"
                    onClick={share}
                  >
                    <span>Share Itinerary</span>
                  </button>
                  <button
                    className="w-full py-2 px-4 flex justify-center items-center space-x-2 rounded-lg bg-purple-500 text-white hover:bg-purple-700 transition duration-300"
                    onClick={shareWithInstagram}
                  >
                    <span>Share on Instagram</span>
                  </button>
                  <button
                    className={`w-full py-2 px-4 flex justify-center items-center space-x-2 rounded-lg ${
                      isSaved ? "bg-green-500" : "bg-blue-500"
                    } text-white hover:bg-blue-700 transition duration-300`}
                    onClick={save}
                    disabled={isSaved}
                  >
                    <span>
                      {isSaved ? "Saved in Account" : "Save to Account"}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-1/2 fixed top-0 right-0 h-screen border-l-4 border-t-4 border-gray-600">
        <MapItinerary
          itineraryData={initialItineraryData}
          mapRef={mapRef}
          city={placeName}
        />

        <BuyMeCoffee />
      </div>
      <div className="w-1/2 bg-[#152535] text-white mt-[100px] pl-[100px]">
        <nav className="w-[100px] text-3xl fixed left-0 pt-8 pl-3">
          <ul>
            {/* Home Button */}
            <li className="block mb-6">
              <button
                className="text-[#92c0d7] flex items-center"
                onClick={() => navigate("/")}
              >
                <FaHome className="mr-2" />
              </button>
            </li>
            <li className="block mb-6 ">
              <a
                className="text-[#92c0d7]"
                href="#day1"
                onClick={() => handleItemClick(0)}
              >
                Day 1
              </a>
            </li>
            <li className="block mb-6">
              <a
                className="text-[#92c0d7]"
                href="#day2"
                onClick={() => handleItemClick(1)}
              >
                Day 2
              </a>
            </li>
            <li className="block mb-6">
              <a
                className="text-[#92c0d7]"
                href="#day3"
                onClick={() => handleItemClick(2)}
              >
                Day 3
              </a>
            </li>
            <li className="block mb-6">
              <a
                className="text-[#92c0d7]"
                href="#day4"
                onClick={() => handleItemClick(3)}
              >
                Day 4
              </a>
            </li>
          </ul>
        </nav>
        <div className="p-12 ">
          {initialItineraryData.slice(0, 4).map((item, index) => (
            <CardItinerary
              key={index}
              item={item}
              dayNum={index}
              isSelected={index === selectedItem}
              onItemImagesChange={handleItemImagesChange}
              city={placeName}
            />
          ))}
        </div>
        <SuccessSnackbar
          open={openSnackbar}
          handleClose={handleCloseSnackbar}
        />
      </div>
      {errorDialogOpen && (
        <div className="fixed z-10 inset-0 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
          <div className="bg-white w-1/2 p-8 rounded-lg z-20">
            <h2 className="text-2xl font-bold mb-4">Server Under Heavy Load</h2>
            <p className="text-lg text-gray-700 mb-4">{errorDialogMessage}</p>
            <button
              className="bg-blue-500 text-white py-2 px-4 rounded-lg"
              onClick={closeErrorDialog}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );

  return isMobile ? mobileComponent : desktopComponent;
};

export default Itinerary;
