import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {
  FaEllipsisV,
  FaTimes,
  FaSignInAlt,
  FaUserPlus,
  FaLightbulb,
  FaUser,
  FaSignOutAlt,
  FaGlobe,
} from "react-icons/fa";
import { UserAuth } from "../context/AuthContext";
import FeedbackForm from "../components/FeebackForm";
import Tooltip from "@material-ui/core/Tooltip";

// Background videos from cloudinary
const vidArray = [
  "https://res.cloudinary.com/djtigzyfc/video/upload/f_auto:video,q_auto/v1/aitravel/suzimjtezozkdpvfhjoo",
  "https://res.cloudinary.com/djtigzyfc/video/upload/f_auto:video,q_auto/v1/aitravel/qq0wegczzuoauvklhndh",
  "https://res.cloudinary.com/djtigzyfc/video/upload/f_auto:video,q_auto/v1/aitravel/jxxaprm4dpi4hkuraeiu",
  "https://res.cloudinary.com/djtigzyfc/video/upload/f_auto:video,q_auto/v1/aitravel/hslbmbpdi5nusfflm0am",
  "https://res.cloudinary.com/djtigzyfc/video/upload/f_auto:video,q_auto/v1/aitravel/o7w7r4a1yqnmn4dqeena",
  "https://res.cloudinary.com/djtigzyfc/video/upload/f_auto:video,q_auto/v1/aitravel/dbilfyjeubn7t6cqtz0l",
  "https://res.cloudinary.com/djtigzyfc/video/upload/f_auto:video,q_auto/v1/aitravel/wgztk8xgkv981ii759ym",
  "https://res.cloudinary.com/djtigzyfc/video/upload/f_auto:video,q_auto/v1/aitravel/xfuhyo9tqmgnweqxewrj",
  "https://res.cloudinary.com/djtigzyfc/video/upload/f_auto:video,q_auto/v1/aitravel/zkzduxprpf7nf71zmknj",
  "https://res.cloudinary.com/djtigzyfc/video/upload/f_auto:video,q_auto/v1/aitravel/vi9on5eaaz4pfogxknva",
  "https://res.cloudinary.com/djtigzyfc/video/upload/f_auto:video,q_auto/v1/aitravel/zoimpi3ftyroplbgxgmb",
  "https://res.cloudinary.com/djtigzyfc/video/upload/f_auto:video,q_auto/v1/aitravel/tja0galuhbg9z3yfv1oc",
];
const randomIndex = Math.floor(Math.random() * vidArray.length);
const randomSrc = vidArray[randomIndex];

export default function RoamReady() {
  const [placeName, setPlaceName] = useState("");
  const [isValidPlace, setIsValidPlace] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Added state for menu
  const { user, logout } = UserAuth();
  const [options, setOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const formRef = useRef(null);
  const [isFetching, setIsFetching] = useState(false);

  const handleInputChange = (e, value, reason) => {
    // Update placeName state
    if (reason === "input") {
      setPlaceName(value);
      if (value.length >= 3) {
        // Check if value length is greater than or equal to 3
        setIsDropdownOpen(true); // Open the dropdown
        // Fetch autocomplete options based on the input value
        fetchOptions(value);
      } else {
        setIsDropdownOpen(false); // Close the dropdown
        setOptions([]);
      }
    }
  };

  const fetchOptions = async (inputValue) => {
    try {
      setIsFetching(true); // Set fetching state to true
      const response = await fetch(
        `${process.env.REACT_APP_api_url}api/autocomplete?input=${inputValue}`
      );
      if (!response.ok) throw Error(response.statusText);
      const data = await response.json();
      setOptions(data);
    } catch (error) {
      console.error("Autocomplete fetch failed", error);
      setOptions([]);
    } finally {
      setIsFetching(false); // Set fetching state to false regardless of success or failure
    }
  };

  const handleBlur = (event) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setPlaceName(event.target.value);
    }
  };

  const handleOutsideClick = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      event.preventDefault();
      setPlaceName((prevPlaceName) => prevPlaceName.trim()); // Update the placeName with the trimmed value
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", handleOutsideClick);

    return () => {
      document.body.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  function toggleMenu() {
    setIsMenuOpen((prevState) => !prevState);
  }

  async function onSubmit(event) {
    event.preventDefault();

    if (placeName.trim() === "") {
      setIsValidPlace(false);
      return;
    }

    setIsValidPlace(true);
    setIsLoading(true); // Start loading

    const uniqueId = uuidv4();

    navigate(`/itinerary/${placeName}/${uniqueId}`);
    setIsLoading(false); // Stop loading
  }

  function validatePlaceName(placeName) {
    // This function now always return true, regardless of the place name.
    return true;
  }

  // Prevent scrolling on homepage
  useEffect(() => {
    document.body.style.overflow = "hidden"; // Prevent scrolling on the body element

    return () => {
      document.body.style.overflow = "auto"; // Restore scrolling when component is unmounted
    };
  }, []);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("Firebase: Signed Out");
    } catch (err) {
      console.error(err);
    }
  };

  // Feedback form
  const handleFeedbackSent = () => {
    alert("Feedback received. Thank you!");
  };

  return (
    <div
      className={`min-h-screen flex pt-40 justify-center ${
        isMenuOpen ? "bg-gray-500" : "bg-black"
      }`}
      style={{ overflow: "hidden" }}
    >
      <video
        className="fixed top-0 left-0 object-cover w-full h-full z-0"
        src={randomSrc}
        autoPlay
        loop
        muted
        playsInline
      />

      {/* Three-dot menu icon */}
      <div className="fixed top-4 right-4 z-40">
        <button className="text-white text-4xl" onClick={toggleMenu}>
          {isMenuOpen ? <FaTimes /> : <FaEllipsisV />}
        </button>
      </div>

      {/* Menu */}
      {isMenuOpen && (
        <div className="fixed top-0 right-0 bottom-0 z-30 bg-black bg-opacity-95 transition duration-300 transform translate-x-0 w-full sm:max-w-xs ">
          <div className="h-full flex flex-col justify-center mt-[-15rem] items-start pl-10">
            {/* Check if user is logged in */}
            {user && user.email ? (
              <>
                {/* Display Account button */}
                <button
                  onClick={() => navigate("/account")}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaUser className="mr-2" /> Account
                </button>

                {/* Display Logout button */}
                <button
                  onClick={handleLogout}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaSignOutAlt className="mr-2" /> Logout
                </button>
              </>
            ) : (
              <>
                {/* Display Login button */}
                <button
                  onClick={() => navigate("/login")}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaSignInAlt className="mr-2" /> Login
                </button>

                {/* Display Signup button */}
                <button
                  onClick={() => navigate("/signup")}
                  className="text-white text-xl mb-4 flex items-center py-2 px-4 w-full text-left"
                >
                  <FaUserPlus className="mr-2" /> Signup
                </button>
              </>
            )}

            {/* About Us */}
            <button
              onClick={() => navigate("/about-us")}
              className="text-white text-xl flex items-center py-2 px-4 w-full text-left"
            >
              <FaLightbulb className="mr-2" /> About Us
            </button>
            <div className="fixed bottom-6 left-4 bg-gray-600 text-white rounded-full p-3 shadow-lg transition duration-300 ease-in-out hover:bg-blue-600 focus:outline-none">
              <FeedbackForm onFeedbackSent={handleFeedbackSent} />
            </div>
          </div>
        </div>
      )}

      <main className="px-4 z-10">
        {!isLoading && (
          <div className=" mx-auto">
            <img src="/new.png" alt="Logo" className="mx-auto mt-0" />
            <h6 className="text-lg text-gray-300 text-center mb-8">
              AI-Powered Journey Planner
            </h6>
          </div>
        )}

        {isLoading ? (
          <div className="flex justify-center items-center h-screen">
            <div className="flex flex-col items-center">
              <p className="text-white text-5xl font-bold mb-10">
                Launching into Exploration
              </p>
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          </div>
        ) : (
          <form ref={formRef} onSubmit={onSubmit} className="max-w-md mx-auto">
            <div className="relative flex items-center">
              <Autocomplete
                id="combo-box-demo"
                options={options}
                getOptionLabel={(option) => option.description}
                noOptionsText={isFetching ? "Loading..." : "NO OPTIONS"}
                style={{ width: "100%" }}
                onChange={(e, value) => {
                  if (value) {
                    setPlaceName(value.description);
                    setIsDropdownOpen(false); // Close the dropdown when an option is selected
                  }
                }}
                onInputChange={handleInputChange}
                open={isDropdownOpen}
                disableListWrap
                clearOnBlur={false} // Set clearOnBlur to false
                onBlur={() => setIsDropdownOpen(false)}
                popupIcon={null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="text"
                    placeholder="Your dream destination"
                    className={`w-full rounded-full border-2 focus:outline-none font-bold focus:border-blue-500 text-gray-800 bg-gray-200 ${
                      !isValidPlace && "border-red-500"
                    }`}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      style: {
                        fontSize: "16px",
                        paddingLeft: "2em",
                        paddingRight: "5em",
                        width: "auto",
                        height: "4em",
                      },
                    }}
                    onBlur={handleBlur} // Add onBlur event handler
                  />
                )}
              />

              <button
                type="submit"
                className="absolute inset-y-0 right-0 top-1/2 transform -translate-y-1/2 text-white font-bold bg-gradient-to-r from-blue-600 to-blue-800 rounded-full px-4 py-1 mr-6 transition duration-300 ease-in-out hover:scale-105 focus:outline-none shadow-lg"
              >
                <span className="animate-pulse">Wander</span>
              </button>
            </div>
            <hr className="my-4" />
            {/* Place Recommendation button */}
            <div className="flex flex-row items-center justify-between mt-4">
              <span className="block text-lg text-gray-200 ml-2">
                Need a destination? Let's explore
              </span>
              <button
                className="text-white flex items-center justify-center bg-blue-600 hover:bg-blue-700 rounded-full px-4 py-2 shadow-md transform hover:scale-105 transition-all duration-300"
                onClick={() => navigate("/spotpick")}
              >
                <FaGlobe className="text-xl mr-1" />
                SpotPick
              </button>
            </div>

            {!isValidPlace && (
              <p className="text-red-500 text-center mt-4 font-bold ">
                <span role="img" aria-label="Error">
                  ⚠️
                </span>{" "}
                Oops! Please enter a valid destination.
              </p>
            )}
          </form>
        )}
      </main>
    </div>
  );
}
